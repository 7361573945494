export const mobile = [
    {
        path: '/mobile',
        name: 'mobile',
        component: () => import('@/views/mobile/index.vue'),
        children: [
            {
                path: 'index',
                name:'mobileIndex',
                component: () => import('@/views/mobile/home/index.vue'),
                meta:{
                    keepAlive:true
                }
            },
            {
                path: 'infomation',
                name: 'infomation',
                component: () => import('@/views/mobile/infomation/index.vue'),
                meta: {
                    title: '资讯',
                    keepAlive:true
                }
            },
            {
                path: 'problem',
                name: 'problem',
                component: () => import('@/views/mobile/problem/index.vue'),
                meta: {
                    title: '在线答疑',
                    keepAlive:true
                }
            }, {
                path: 'user',
                name:"user",
                component: () => import('@/views/mobile/user/index'),
                meta: {
                    title: '我的',
                    keepAlive:true
                }
            },
        ]
    },
    {
        path: '/mobile/home/lesson',
        name:'lesson',
        component: () => import('@/views/mobile/home/lesson'),
        meta: {
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            console.log(from)
            if(from.name=='mobileIndex'){
                to.meta.keepAlive=false;
            }
            next()
        },
    }
]
