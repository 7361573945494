import { createApp,ObjectDirective } from 'vue'
import App from './App.vue'
import router from './router';
const app = createApp(App);
app.config.globalProperties.serverImg = process.env.VUE_APP_BASE_API;
app.config.globalProperties.back = ()=>{
  history.back();
};
import utils from './utils/common.js';
Object.keys(utils).forEach((key)=>{
    app.config.globalProperties['$'+key]=utils[key];
})
import { Lazyload } from 'vant';
const throttleDirective = {
    beforeMount(el, binding) {
      let throttled = false;
      let delay = 3000; // 默认节流时间
      el.addEventListener('click', function(event) {
        if (!throttled) {
          binding.value(event);
          throttled = true;
          setTimeout(() => {
            throttled = false;
         }, delay);
        }
      });
    },
    beforeUnmount(el) {
      el.removeEventListener('click', throttleDirective);
    }
  };
app.directive('throttle', throttleDirective);
import '@/assets/css/common.scss';
import Icon from '@/components/g-icon';
app.component('Icon', Icon);
import Empty from '@/components/g-empty';
app.component('Empty', Empty);
app.use(router).use(Lazyload);
app.mount('#app');
