import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e6ce1b6a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "empty flex-cc"
};
const _hoisted_2 = ["src"];
export default {
  __name: 'g-empty',
  props: {
    img: {
      type: String,
      default: require("@/assets/img/empty.png")
    },
    desc: {
      type: String,
      default: '暂无内容~'
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: __props.img,
        alt: ""
      }, null, 8, _hoisted_2), _createElementVNode("p", null, _toDisplayString(__props.desc), 1)]);
    };
  }
};