import { createRouter, createWebHashHistory,onBeforeRouteLeave } from 'vue-router'
import {family} from './family.js'
import {enterCenter} from './enterCenter.js'
import {activeInfo} from './activeInfo.js'
import {account} from './account.js';
import {mobile} from './mobile.js';
const routes = [
    ...enterCenter,
    ...activeInfo,
    ...family,
    ...account,...mobile
]
// 创建router实例
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
