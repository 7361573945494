import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'g-icon',
  props: {
    color: String,
    name: String,
    size: {
      type: [String, Number],
      default: "12"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("i", {
        class: _normalizeClass(["iconfont", __props.name]),
        style: _normalizeStyle({
          color: __props.color,
          fontSize: __props.size + 'px'
        })
      }, null, 6);
    };
  }
};