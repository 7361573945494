export const activeInfo=[
    {
        path: '/activeInfo/wc/home',
        name: 'wcHome',
        component: () => import('@/views/activeInfo/wc/home'),
        meta:{
            keepAlive:true
        }
    },
    {
        path: '/activeInfo/wc/info',
        name: 'wcInfo',
        component: () => import('@/views/activeInfo/wc/info'),
        meta:{
            title:'活动资讯',
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            if(from.name=='wcHome'){
                to.meta.keepAlive=false;
            }
            next()
        }
    },
    {
        path: '/activeInfo/wc/detail',
        name: 'activeNewDetail',
        component: () => import('@/views/activeInfo/wc/detail'),
        meta:{
            title:'活动资讯详情',
        }
    },
    {
        path: '/activeInfo/wc/activitiesJoin/:id',
        name: 'wxActivitiesJoin',
        component: () => import('@/views/activeInfo/wc/activitiesJoin'),
        meta:{
            title:'活动报名列表',
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            if(from.name=='wcHome'){
                to.meta.keepAlive=false;
            }
            next()
        }
    },
    {
        path: '/activeInfo/wc/activities',
        name: 'wcActivities',
        component: () => import('@/views/activeInfo/wc/activities'),
        meta:{
            title:'活动报名',
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            if(from.name=='wcHome'){
                to.meta.keepAlive=false;
            }
            next()
        }
    },
    {
        path: '/activeInfo/wc/activitiesDetail/:id',
        name: 'wcActivitiesDetail',
        component: () => import('@/views/activeInfo/wc/activitiesDetail'),
        meta:{
            title:'活动报名详情',
            keepAlive:!true
        }
    },
    {
     path: '/activeInfo/enrollment',
     name: 'enrollment',
     component: () => import('@/views/activeInfo/enrollment'),
     meta:{
         title:'招生简章',
         keepAlive:true
     },},
    {
     path: '/activeInfo/process',
     name: 'process',
     component: () => import('@/views/activeInfo/process'),
     meta:{
         title:'报名流程'
     },
    },
    {
        path: '/activeInfo/wc/competition',
        name: 'wccompetition',
        component: () => import('@/views/activeInfo/wc/competition'),
        meta:{
            title:'参赛报名',
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            if(from.name=='wcHome'){
                to.meta.keepAlive=false;
            }
            next()
        }
    },
    {
        path: '/activeInfo/wc/join/:id',
        name: 'wcJoin',
        component: () => import('@/views/activeInfo/wc/join'),
        meta:{
            title:'参赛报名'
        }
    },
]