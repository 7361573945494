export default {
    // 阻止页面滚动
    stop() {
        var box = function(e) {
            passive: false;
        };
        document.body.style.overflow = 'hidden';
        document.addEventListener("touchmove", box, false);
    },
// 允许页面滚动
    move() {
        var box = function(e) {
            passive: false
        };
        document.body.style.overflow = ''; // 出现滚动条
        document.removeEventListener("touchmove", box, false);
    }
}
