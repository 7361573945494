
export const enterCenter=[
    {
        path: '/',
        name: '/',
        component: () => import('@/views/enterCenter/center'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/enterCenter/center/home'),
                meta: {
                    title: '中心简介',
                    show: true,
                    keepAlive:true
                }
            },
            {
                path: '/enterCenter/appearance',
                name: 'appearance',
                component: () => import('@/views/enterCenter/center/appearance'),
                meta: {
                    title: '中心风貌',
                    show: true,
                    keepAlive:true
                }
            },
            {
                path: '/enterCenter/honor',
                name: 'honor',
                component: () => import('@/views/enterCenter/center/honor'),
                meta: {
                    title: '中心荣誉',
                    show: true,
                     keepAlive:true,
                }
            },
            {
                path: '/enterCenter/xc',
                name: 'xc',
                component: () => import('@/views/enterCenter/center/xc'),
                meta: {
                    title: '宣传片',
                    show: true,
                     keepAlive:true,
                }
            },  
        ]
    },

    {
        path: '/new',
        name: 'new',
        component: () => import('@/views/enterCenter/new'),
        children: [
            {
                path: '/new/dynamic',
                name: 'dynamic',
                component: () => import('@/views/enterCenter/new/dynamic'),
                meta: {
                    title: '新闻动态',
                    show: true
                }
            },
            {
                path: '/new/notice',
                name: 'notice',
                component: () => import('@/views/enterCenter/new/notice'),
                meta: {
                    title: '通知公告',
                    show: true
                }
            },
            {
                path: '/new/active',
                name: 'active',
                component: () => import('@/views/enterCenter/new/active'),
                meta: {
                    title: '活动信息',
                    show: true
                }
            },
            {
                path: '/new/meida',
                name: 'meida',
                component: () => import('@/views/enterCenter/new/meida'),
                meta: {
                    title: '媒体关注',
                    show: true
                }
            }, 
        ]
    },
    {
        path: '/teacher',
        name: 'teacher',
        component: () => import('@/views/enterCenter/teacher'),
        children: [
            {
                path: '/teacher/index',
                name: 'teacherIndex',
                component: () => import('@/views/enterCenter/teacher/teacher'),
                meta: {
                    title: '全部',
                    show: true
                }
            },
            {
                path: '/teacher/jtjy',
                name: 'jtjy',
                component: () => import('@/views/enterCenter/teacher/jtjy'),
                meta: {
                    title: '家庭教育',
                    show: true
                }
            },
            {
                path: '/teacher/nxcz',
                name: 'nxcz',
                component: () => import('@/views/enterCenter/teacher/nxcz'),
                meta: {
                    title: '女性成长',
                    show: true
                }
            },
            {
                path: '/teacher/etjy',
                name: 'etjy',
                component: () => import('@/views/enterCenter/teacher/etjy'),
                meta: {
                    title: '儿童教育',
                    show: true
                }
            }
        ]
    },
     {
        path: '/teacher/detail',
        name: 'teacherDetail',
        component: () => import('@/views/enterCenter/teacher/detail'),
        meta: {
            title: '详情',
            show: false
        }
    },
    {
        path: '/new/detail',
        name: 'newDetail',
        component: () => import('@/views/enterCenter/new/detail'),
        meta: {
            title: '详情',
            show: false
        }
    },
    {
        path: '/enterCenter/student',
        name: 'student',
        component: () => import('@/views/enterCenter/student'),
        meta: {
            title: '会员中心',
            show: false
        }
    },
    {
        path: '/enterCenter/student/order',
        name: 'studentOrder',
        component: () => import('@/views/enterCenter/student/order'),
        meta: {
            title: '我的订单'
        },
    },
    {
        path: '/enterCenter/student/application',
        name: 'studentApplication',
        component: () => import('@/views/enterCenter/student/application'),
        meta: {
            title: '我的报名'
        }
    },
    {
        path: '/enterCenter/student/favorite',
        name: 'favoraite',
        component: () => import('@/views/enterCenter/student/favorite'),
        meta: {
            title: '我的收藏记录',
            show: false,
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
           if(from.name=='student'){
               to.meta.keepAlive=false;
           }
           next()
       }
    },
    {
        path: '/enterCenter/student/evaluate',
        name: 'evaluate',
        component: () => import('@/views/enterCenter/student/evaluate'),
        meta: {
            title: '我的评论记录',
            show: false,
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            if(from.name=='student'){
                to.meta.keepAlive=false;
            }
            next()
        },
    },
    {
        path: '/enterCenter/student/lesson',
        name: 'lesson',
        component: () => import('@/views/enterCenter/student/lesson'),
        meta: {
            title: '我的课程',
            show: false,
            keepAlive:true
        }
    },
    {
        path: '/enterCenter/student/editUser',
        name: 'editUser',
        component: () => import('@/views/enterCenter/student/editUser'),
    },
   
    {
        path: '/enterCenter/detail',
        name: 'detail',
        component: () => import('@/views/enterCenter/center/detail'),
        meta: {
            title: '首页文章详情',
            show: false
        }
    }
]