import { KeepAlive } from 'vue';

export const family=[
    {
        path: '/parentsClass/familyTheme',
        name: 'familyTheme',
        component: () => import('@/views/parentsClasses/familyTheme/home'),
        meta: {
            title: '家庭主题活动',
        }
    },
    {
        path: '/parentsClass/courses',
        name: 'courses',
        component: () => import('@/views/parentsClasses/courses/home'),
        meta: {
            title: '家长课程',
            keepAlive:true
        },
        beforeEnter: (to, from, next) => {
            
            document.title = '新余市家长课程'
            next()
        }
    },
    {
        path: '/parentsClass/courses/detail',
        name: 'coursesDetail',
        component: () => import('@/views/parentsClasses/courses/detail'),
        meta: {
            title: '家长课程详情',
            keepAlive:true,
           
        }, 
        beforeEnter: (to, from, next) => {
            console.log(from)
            if(from.name=='courses'||from.name=='evaluate'||from.name=='favoraite'||from.name=='lesson'){
                to.meta.keepAlive=false;
            }
            document.title = '新余市家长课程'
            next()
        }
    },
    {
        path: '/parentsClass/courses/evaluate',
        name: 'coursesEvaluate',
        component: () => import('@/views/parentsClasses/courses/evaluate'),
        meta: {
            title: '评论',
        }
    }

]